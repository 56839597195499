import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

// import Navbar from "./navbar/navbar"
// import Footer from "./footer/footer"

const Layout = ({ children }) => {
  // useEffect(() => {
  //   // Transition link
  //   const wrapper = document.querySelector("main")
  //   const anchor = document.querySelectorAll("a")

  //   wrapper.classList.add("animated", "fadeIn")
  //   anchor.forEach(item => {
  //     const navigation = e => {
  //       const redirect = item.getAttribute("href")
  //       e.preventDefault()

  //       wrapper.classList.remove("fadeIn")
  //       wrapper.classList.add("fadeOut")
  //       setTimeout(() => {
  //         navigate(redirect)
  //       }, 250)
  //     }
  //     if (item.getAttribute("href") && !item.getAttribute("target")) {
  //       item.addEventListener("click", navigation)
  //     }
  //   })

  //   return () => {
  //     anchor.forEach(item => {
  //       const navigation = e => {
  //         const redirect = item.getAttribute("href")
  //         e.preventDefault()

  //         wrapper.classList.remove("fadeIn")
  //         wrapper.classList.add("fadeOut")
  //         setTimeout(() => {
  //           navigate(redirect)
  //         }, 250)
  //       }
  //       if (item.getAttribute("href") && !item.getAttribute("target")) {
  //         item.removeEventListener("click", navigation)
  //       }
  //     })
  //   }
  // }, [])

  return (
    <>
      {/* <Navbar /> */}
      <main>
        {children}
        {/* <Footer /> */}
      </main>
    </>
  )
}

export default Layout
